import { dateValidation } from "@/lib/validations/reactHookFormValidations";

export function convertStringDateToDateType(dateString: string) {
  const dateRegexPattern = dateValidation.pattern.value;

  const isValidDate = dateRegexPattern.test(dateString);

  if (!isValidDate) {
    console.warn("Invalid date string", dateString);
    return null;
  }
  // Split the string into parts
  const [month, day, year] = dateString.split("/").map(Number);

  // Create a new Date object with the parts
  // Note: Month is 0-indexed in JavaScript Date, so subtract 1
  const date = new Date(year, month - 1, day);
  return date;
}
